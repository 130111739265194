import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Column, Image, Text, Link } from 'components/atoms';

const RelatedCasesItem = ({
  data,
  className,
  index,
  large,
  onClick,
  offset,
}) => {
  const { path, featured_media, acf, title: post_title } = data || {};
  const { description, private_access } = acf || {};
  const {
    source_url,
    alt_text,
    acf: featured_media_acf,
    title: image_title,
    media_details,
  } = featured_media || {};
  const { placeholder_base64 } = featured_media_acf || {};

  const [isHover, setIsHover] = useState(false);

  const image = large ? (
    <Image
      className={cn(
        'related-cases__item-image',
        'related-cases__item-image_large',
        isHover && 'related-cases__item-image_hover'
      )}
      src={source_url}
      alt={alt_text}
      title={image_title}
      placeholder64={placeholder_base64}
      lazyLoading
      imageFit="cover"
      imageAspect={695 / 401}
      image={media_details}
    />
  ) : (
    <Image
      className={cn(
        'related-cases__item-image',
        isHover && 'related-cases__item-image_hover'
      )}
      src={source_url}
      alt={alt_text}
      title={image_title}
      placeholder64={placeholder_base64}
      lazyLoading
      imageFit="cover"
      imageAspect={335 / 180}
      image={media_details}
    />
  );

  return large ? (
    <>
      <Column
        className={cn(
          'related-cases__item',
          'related-cases__item_large',
          !offset ? 'offset-lg-2' : 'offset-lg-1',
          [className]
        )}
        lg="6"
      >
        {private_access ? (
          <div
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            tabIndex="0"
            className="related-cases__item--overflow"
          >
            {image}
          </div>
        ) : (
          <Link href={path}>{image}</Link>
        )}
      </Column>
      <Column
        className={cn('related-cases__item', 'related-cases__item_content', [
          className,
        ])}
        lg="3"
      >
        {private_access ? (
          <div
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            tabIndex="0"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <Text className="related-cases__item__title headline-4">
              {post_title}
            </Text>
          </div>
        ) : (
          <Link
            href={path}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <Text className="related-cases__item__title headline-4">
              {post_title}
            </Text>
          </Link>
        )}
        <div
          className="body-sm related-cases__item__description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </Column>
    </>
  ) : (
    <Column
      className={cn(
        'related-cases__item',
        index === 0 && (!offset ? 'offset-lg-2' : 'offset-lg-1'),
        [className]
      )}
      lg="3"
    >
      {private_access ? (
        <>
          <div
            onClick={onClick}
            onKeyDown={onClick}
            role="button"
            tabIndex="0"
            className="related-cases__item--overflow"
          >
            {image}
          </div>
          <div onClick={onClick} onKeyDown={onClick} role="button" tabIndex="0">
            <Text
              className="body-sm body-sm_bold related-cases__item__title related-cases__item__title_disabled"
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              onClick={onClick}
            >
              {post_title}
            </Text>
          </div>
        </>
      ) : (
        <>
          <Link href={path}>{image}</Link>
          <Link
            href={path}
            isRouter={false}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <Text className="body-sm body-sm_bold">{post_title}</Text>
          </Link>
        </>
      )}
    </Column>
  );
};

RelatedCasesItem.defaultProps = {
  className: '',
};

RelatedCasesItem.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  large: PropTypes.bool,
  onClick: PropTypes.func,
};

export default RelatedCasesItem;
