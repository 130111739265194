import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text } from 'components/atoms';
import RelatedCasesItem from 'components/molecules/RelatedCasesItem';
import IndustriesPopup from 'components/molecules/IndustriesPopup';

import { useAllCaseStudyWorkArray } from 'graphql/hooks';

const RelatedCases = ({ data, className, caseStudy, pageLink }) => {
  const largeCards = [];
  const smallCards = [];

  const large = true;

  const { case_studies, caption } = data;

  const allCards = useAllCaseStudyWorkArray();

  const { title } = caption;

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isShowPopupMessage, setIsShowPopupMessage] = useState(false);
  const [caseStudyDataState, setCaseStudyDataState] = useState(null);

  if (!case_studies || !case_studies.length) {
    return null;
  }

  if (large) {
    switch (case_studies.length) {
      case 0:
        break;
      case 1:
        largeCards.push(...case_studies.slice(0, 1));
        break;
      case 2:
        largeCards.push(...case_studies);
        break;
      default:
        largeCards.push(...case_studies.slice(0, 1));
        smallCards.push(...case_studies.slice(1, 4));
    }
  } else {
    smallCards.push(...case_studies.slice(0, 3));
  }

  const largeCardsList = largeCards.map((item, index) => {
    const { wordpress_id } = item;

    const data = allCards.find(card => wordpress_id === card.wordpress_id);

    return (
      <RelatedCasesItem
        data={data}
        large={true}
        key={index}
        index={index}
        offset={caseStudy}
        onClick={() => {
          setCaseStudyDataState(item);
          setIsShowPopup(true);
        }}
      />
    );
  });

  const smallCardsList = smallCards.map((item, index) => {
    const { wordpress_id } = item;

    const data = allCards.find(card => wordpress_id === card.wordpress_id);

    return (
      <RelatedCasesItem
        data={data}
        key={index}
        index={index}
        offset={caseStudy}
        onClick={() => {
          setCaseStudyDataState(item);
          setIsShowPopup(true);
        }}
      />
    );
  });

  return (
    <Section className={cn('related-cases', [className])} id={data.id}>
      <Container>
        <Row>
          <Column
            className={cn(
              !caseStudy ? 'offset-lg-2' : 'offset-lg-1',
              'related-cases__text'
            )}
            lg="7"
          >
            <Text tag="h3" className="headline-2">
              {title}
            </Text>
          </Column>
        </Row>
        <Row>{largeCardsList}</Row>
        <Row>{smallCardsList}</Row>
        {isShowPopup && (
          <IndustriesPopup
            link={pageLink}
            caseStudyData={caseStudyDataState}
            onClose={() => setIsShowPopup(false)}
            messagePopup={() => setIsShowPopupMessage(true)}
          />
        )}
        {isShowPopupMessage && (
          <IndustriesPopup
            className="industries-popup_message"
            message="Thank you, your request was successful!"
            onClose={() => setIsShowPopupMessage(false)}
          />
        )}
      </Container>
    </Section>
  );
};

RelatedCases.defaultProps = {
  className: '',
};

RelatedCases.propTypes = {
  data: PropTypes.object,
  dataCaseStudy: PropTypes.array,
  dataWorks: PropTypes.array,
  large: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  pageLink: PropTypes.string,
};

export default RelatedCases;
